
import { sortItem, sortOrders } from "@/enums/main/sortOrders";

import {
  ComputedRef,
  defineComponent,
  PropType,
  computed,
  toRefs,
  watch
} from "vue";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import AppTable from "@/components/Table/ts/AppTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";
import { ICategories } from "@/api/interfaces/categories/ICategories";
import { badgeTypes } from "@/components/ui/AppBadge/interfaces";

type tableHeadCells = ITableHeadCell[];

export type categoriesTableSort = {
  byName: sortItem;
};

export default defineComponent({
  name: "CategoryTable",
  components: { AppTable },

  emits: {
    toggleBlock: null,
    delete: null,
    changeViewConfiguration: null,
    removingCategory: null,
    editingCategory: null
  },
  setup(props, { emit }) {
    // base

    const { byName } = toRefs(props.sort);

    const initialSortOptions: categoriesTableSort = {
      byName: { order: sortOrders.turnOff, keyName: "title" }
    };

    // table model
    const categoriesList: ComputedRef<ICategories[]> = computed(
      () => props.categoriesList
    );
    const tableRefs = useBaseTable({
      initialViewConfiguration: { ...props.initialViewConfiguration },

      initialSortOptions,
      model: categoriesList,
      generateRows(categoriesList: ICategories[]): ITableBodyRow[] {
        return categoriesList.map(
          (category: ICategories): ITableBodyRow => {
            const { title, active, id } = category;
            return {
              cells: [
                {
                  label: title,
                  sort: {
                    keyName: byName?.value?.keyName || "",
                    value: title,
                    order: byName?.value?.order
                  }
                },
                {
                  badge: {
                    type: active
                      ? badgeTypes.lightSecondary
                      : badgeTypes.danger,

                    label: active ? "Active" : "Inactive"
                  }
                },
                {
                  userActions: true
                }
              ],
              id: id
            };
          }
        );
      },

      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    watch(
      props.initialViewConfiguration,
      (updatedConfiguration: ITableViewConfiguration) => {
        changeViewConfiguration(updatedConfiguration);
      }
    );

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    categoriesList: {
      type: Array as PropType<ICategories[]>,
      required: true
    },

    sort: {
      type: Object as PropType<categoriesTableSort>,
      required: true
    },

    initialViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },

  computed: {
    tableHead(): tableHeadCells {
      const { byName } = this.currentSort;
      return [
        {
          sort: {
            keyName: byName?.keyName,
            order: byName?.order
          },
          size: "fluid",
          label: "name",
          id: "category-name"
        },
        {
          size: "sm",
          label: "Status",
          id: "category-status"
        },
        {
          size: "xs",
          label: "Action",
          id: "category-action"
        }
      ];
    }
  },

  methods: {
    DeletingInfo(categoryId: any, info: ICategories) {
      this.$emit("removingCategory", categoryId, info);
    },

    EditingInfo(categoryId: ICategories, info: ICategories) {
      this.$emit("editingCategory", categoryId, info);
    }
  }
});
